export interface PlotPoint {
  id?: number;
  landDevelopmentId?: number;
  blockId?: number;
  plotId?: number;
  order?: number;
  latitude?: number;
  longitude?: number;
}

interface Plot {
  id?: number;
  identifier?: string;
  blockIdentifier?: string;
  landDevelopmentId?: number;
  blockId?: number;
  info1?: string;
  info2?: string;
  info3?: string;
  remoteId?: number;
  monthlyPayment?: number;
  totalPayment?: number;
  paymentsAmount?: number;
  surface?: number;
  isTaken?: boolean;
  plotPoints?: PlotPoint[];
}

export const newPlot = (): Plot => {
  return {};
};

export default Plot;
