import Block from "./blocks";
import Plot from "./plots";

interface LandDevelopment {
  id?: number;
  remoteId?: number;
  identifier?: string;
  description?: string;
  siteUrl?: string;
  googleMapsUrl?: string;
  obs?: string;
  blocksAmount?: number;
  blocks?: Block[];
  plots?: Plot[];
}

export const newLandDevelopment = (): LandDevelopment => {
  return {};
};

export default LandDevelopment;
