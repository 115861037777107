import Plot from "./plots";

interface Block {
  id?: number;
  identifier?: string;
  order?: number;
  landDevelopmentId?: number;
  latitude?: number;
  longitude?: number;
  plots?: Plot[];
}
export const newBlock = (): Block => {
  return {};
};

export default Block;
