import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { createItem, updateItem, ItemRequestStatus } from "../../api/generics";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import LandDevelopment, {
  newLandDevelopment,
} from "../../models/land_development";

interface LandDevelopmentFormProps {
  initialLandDevelopment?: LandDevelopment;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const SupplierForm: React.FC<LandDevelopmentFormProps> = ({
  initialLandDevelopment,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const [editingLandDevelopment, setEditingLandDevelopment] =
    useState<LandDevelopment>(
      initialLandDevelopment ? initialLandDevelopment : newLandDevelopment()
    );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);

  const onIdentifierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingLandDevelopment({
      ...editingLandDevelopment,
      identifier: e.target.value,
    });
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingLandDevelopment({
      ...editingLandDevelopment,
      description: e.target.value,
    });
  };

  const onGoogleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingLandDevelopment({
      ...editingLandDevelopment,
      googleMapsUrl: e.target.value,
    });
  };

  const onSiteUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingLandDevelopment({
      ...editingLandDevelopment,
      siteUrl: e.target.value,
    });
  };

  const onObsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingLandDevelopment({
      ...editingLandDevelopment,
      obs: e.target.value,
    });
  };

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingLandDevelopment((editingLandDevelopment) => {
      let amount: number | undefined = undefined;

      if (!isNaN(parseInt(e.target.value))) {
        amount = parseInt(e.target.value);
      }

      return { ...editingLandDevelopment, blocksAmount: amount };
    });
  };

  const onRemoteIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let remoteId: number | undefined = undefined;
    if (!isNaN(parseInt(e.target.value))) {
      remoteId = parseInt(e.target.value);
    }
    let newEditingItem: LandDevelopment = { ...editingLandDevelopment };
    if (remoteId) {
      newEditingItem.remoteId = remoteId;
      setEditingLandDevelopment(newEditingItem);
    }
  };

  const onSave = async () => {
    setSubmitting(true);

    let requestPromise: Promise<ItemRequestStatus<LandDevelopment>>;
    if (editingLandDevelopment.id === undefined) {
      requestPromise = createItem<LandDevelopment>(
        "/land_developments/create/",
        editingLandDevelopment
      );
    } else {
      requestPromise = updateItem<LandDevelopment>(
        `/land_developments/${editingLandDevelopment.id}/`,
        editingLandDevelopment
      );
    }

    const clientStatus = await requestPromise;

    if (clientStatus.status !== SUCCESS) {
      if (clientStatus.errors !== undefined) {
        setErrors(clientStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (clientStatus.detail !== undefined) {
        message = clientStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingLandDevelopment(newLandDevelopment());
  };

  useEffect(() => {
    setEditingLandDevelopment(
      initialLandDevelopment ? initialLandDevelopment : newLandDevelopment()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLandDevelopment]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingLandDevelopment.identifier)}
                onChange={onIdentifierChange}
                placeholder="Nombre de Loteamiento"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("identifier", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Link de contacto:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(
                  editingLandDevelopment.description
                )}
                onChange={onDescriptionChange}
                placeholder="Link de contacto (https://wa.me/<number> )"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("description", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Link de google maps:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(
                  editingLandDevelopment.googleMapsUrl
                )}
                onChange={onGoogleChange}
                placeholder="Link de google maps"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("googleMapsUrl", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Link de compartir:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingLandDevelopment.siteUrl)}
                onChange={onSiteUrlChange}
                placeholder="Link de compartir"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("siteUrl", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Observaciones:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingLandDevelopment.obs)}
                onChange={onObsChange}
                placeholder="Observaciones"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("obs", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>ID remota:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="number"
                min={0}
                placeholder="ID Remora API"
                value={editingLandDevelopment.remoteId}
                onChange={onRemoteIdChange}
                step={"1"}
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("remoteId", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        {editingLandDevelopment.id === undefined ? (
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Cantidad de Manzanas:</CLabel>
              </CCol>
              <CCol>
                <CInput
                  type="number"
                  placeholder="Cantidad"
                  value={
                    editingLandDevelopment.blocksAmount !== undefined
                      ? editingLandDevelopment.blocksAmount
                      : 0
                  }
                  onChange={onAmountChange}
                ></CInput>
                <FieldErrors
                  errors={getFieldErrors("blocksAmount", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
        ) : (
          <></>
        )}

        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default SupplierForm;
